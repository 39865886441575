<template>
  <div>
    <div class="mb-4">
      <h2>Produktionsauftragsübersicht</h2>
    </div>

    <dashboard-element>
      <div class="bg-white rounded-md p-4 mb-4 shadow-md print:hidden">
        Anzeigen:
        <label class="flex flex-row items-center">
          <input class="mr-2" type="checkbox" />
          Erledigte Aufträge
        </label>
        <label class="flex flex-row items-center">
          <input class="mr-2" type="checkbox" />
          In Produktion
        </label>
        <label class="flex flex-row items-center">
          <input class="mr-2" type="checkbox" />
          Nicht geplant
        </label>
      </div>
      <dashboard-head
        class="border-b border-gray-200"
        title="Produktionsaufträge"
      />
      <dashboard-table>
        <div class="flex flex-col">
          <div class="shadow overflow-hidden sm:rounded-b-lg">
            <table
              class="min-w-full divide-y divide-gray-200 dark:divide-gray-900"
            >
              <thead class="bg-gray-50 dark:bg-gray-900 dark:text-white">
                <tr>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    <div>Prod.-Auftr.-Nr.</div>
                    <div>Auftrags-Nr.</div>
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Kunde
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Artikel
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Menge
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Liefertermin
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    KW
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    scope="col"
                  >
                    Eilig
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-900"
              >
                <tr
                  v-for="auftrag in auftraege"
                  :key="auftrag.auftragsnr"
                  :class="{
                    'dark:bg-green-700 bg-green-50 text-white dark:text-gray-900 hover:bg-green-100': checkedPa.includes(
                      auftrag.auftragsnr
                    ),
                    'bg-gray-50 dark:bg-gray-800': auftrag.status === 3
                  }"
                  class="hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="w-4">
                        <input
                          v-if="auftrag.status < 3"
                          id="takeit"
                          v-model="checkedPa"
                          :name="auftrag.auftragsnr"
                          :value="auftrag.auftragsnr"
                          type="checkbox"
                        />
                      </div>
                      <div class="ml-4">
                        <div
                          class="text-sm font-medium text-gray-900 dark:text-white"
                        >
                          <router-link :to="`/pa/${auftrag.auftragsnr}`">{{
                            auftrag.auftragsnr
                          }}</router-link>
                        </div>
                        <div class="text-sm text-gray-500">
                          AB {{ auftrag.belegnr }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">
                      {{ auftrag.kunde }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">
                      <router-link :to="`/artikel/${auftrag.artikelcode}`">{{
                        auftrag.artikelcode
                      }}</router-link>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">
                      {{ auftrag.menge }} Stück
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      v-if="auftrag.status === 1"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Geplant
                    </span>
                    <span
                      v-if="auftrag.status === 0"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                    >
                      Nicht geplant
                    </span>
                    <span
                      v-if="auftrag.status === 2"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                    >
                      In Produktion
                    </span>
                    <span
                      v-if="auftrag.status === 3"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
                    >
                      Erledigt
                    </span>
                    <span
                      v-if="auftrag.eilig"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                    >
                      Eilig
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ formatDate(auftrag.liefertermin) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ auftrag.kw }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <label class="flex items-center cursor-pointer">
                      <!-- toggle -->
                      <div class="relative">
                        <!-- input -->
                        <input
                          type="checkbox"
                          id="eilig"
                          :value="auftrag.auftragsnr"
                          :checked="auftrag.eilig"
                          @change="markAsEilig"
                          class="sr-only"
                        />
                        <!-- line -->
                        <div
                          class="block bg-gray-600 w-6 h-4 rounded-full"
                        ></div>
                        <!-- dot -->
                        <div
                          class="dot absolute left-1 top-1 bg-white w-2 h-2 rounded-full transition"
                        ></div>
                      </div>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="checkedPa.length > 0" class="p-3 flex items-end">
          <label>
            Kalenderwoche
            <input
              type="number"
              max="53"
              min="1"
              v-model="kw"
              style="max-width: 100px;"
              class="mr-2"
            />
          </label>
          <button
            class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
            @click="updateData"
          >
            Produktionsplan erstellen
          </button>
          <button
            class="text-red-900 bg-red-300 hover:bg-red-400 rounded px-4 py-2 text-center"
            @click="removePlanning"
            v-if="showRemoveButton"
          >
            Planung entfernen
          </button>
        </div>
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import DashboardElement from "../components/dashboard/DashboardElement.vue";
import DashboardHead from "../components/dashboard/DashboardHead.vue";
import DashboardTable from "../components/dashboard/DashboardTable.vue";
import { supabase } from "@/plugins/supabase";
import moment from "moment";

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardTable
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      checkedPa: [],
      pa: [],
      filter: 3,
      kw: moment().week() + 1
    };
  },
  computed: {
    auftraege() {
      return this.pa.filter(pa => pa.status !== this.filter);
    },
    showRemoveButton() {
      return this.checkedPa.map(() => {
        this.pa;
      });
    }
  },
  methods: {
    markAsEilig(e) {
      console.log(e.target.value);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async loadData() {
      const { data: produktionsauftraege, error } = await supabase
        .from("produktionsauftraege")
        .select("*");

      !error ? (this.pa = produktionsauftraege) : console.error(error);
    },
    updateData: () => {
      this.checkedPa.map(async auftrag => {
        await supabase
          .from("produktionsauftraege")
          .update({ kw: this.kw, status: 1 })
          .eq("auftragsnr", auftrag);
      });

      this.checkedPa = [];
    },
    removePlanning: () => {
      this.checkedPa.map(async auftrag => {
        await supabase
          .from("produktionsauftraege")
          .update({ kw: null, status: 0 })
          .eq("auftragsnr", auftrag);
      });

      this.checkedPa = [];
    }
  }
};
</script>

<style scoped lang="scss">
input:checked {
  ~ .dot {
    transform: translateX(100%);
    background-color: #48bb78;
  }
}
</style>
